import { calFee, calMonthlyPayment } from '@/helpers/currency';
import { FEE1, FEE2 } from '@/constants';

export function computeTransFee(term: number, money: number) {
  if (term === 1) {
    return calFee(money, FEE1);
  }
  return calFee(money, FEE2);
}

export function computeMonthlyPayment(
  term: number,
  money: number,
  interest: number,
  isPartner?: boolean,
) {
  if (isPartner) {
    // partner fee
    return calMonthlyPayment(money, interest, term);
  } else {
    // interloan fee
    if (term === 1) {
      return money;
    } else {
      return calMonthlyPayment(money, interest, term);
    }
  }
}
