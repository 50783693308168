<template>
  <div class="base-input relative">
    <div class="flex items-center border-b border-0">
      <div class="relative w-full pl-1">
        <input
          :id="id"
          :value="modelValue"
          :placeholder="placeholder"
          :type="typeInput"
          :inputmode="mode"
          :maxlength="length"
          @input="handleChange"
          @focus="handleFocus"
          :class="{
            'text-center': textCenter,
            spacing: spacing,
            'font-bold': bold,
            uppercase: uppercase,
          }"
          class="focus:outline-none w-full py-1"
        />
        <span class="currency absolute right-0 bottom-1">{{ suffix }}</span>
      </div>
      <div v-if="clearable" class="base-input-clear ml-2 flex items-center">
        <button @click="clearData" class="focus:outline-none">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-6 w-6 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            style="width:20px"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M10 14l2-2m0 0l2-2m-2 2l-2-2m2 2l2 2m7-2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ['focus', 'update:modelValue', 'change-data'],
  name: 'BaseInput',
  props: {
    modelValue: {
      type: String,
    },
    length: {
      type: String,
    },
    mode: {
      type: String,
    },
    id: {
      type: String,
    },
    typeInput: {
      type: String,
    },
    suffix: {
      type: String,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    spacing: {
      type: Boolean,
      default: false,
    },
    bold: {
      type: Boolean,
      default: false,
    },
    textCenter: {
      type: Boolean,
      default: false,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
    },
  },

  methods: {
    clearData() {
      this.$emit('change-data', '');
    },
    handleChange(event) {
      if (this.length && this.mode === 'numeric') {
        event.target.value = event.target.value.replace(/\D/g, '');
      }
      this.$emit('update:modelValue', event.target.value);
    },
    handleFocus() {
      this.$emit('focus');
    },
  },
};
</script>

<style lang="scss" scoped>
.base-input {
  text-align: left;

  .currency {
    color: #b3c3dd;
  }

  .spacing {
    letter-spacing: 5px;
  }
}
</style>
