/*
Language-neutral NUMBERTEXT and MONEYTEXT functions for LibreOffice Calc

version 1.0.6 (2020-06-01)

Numbertext LibreOffice extension,
Soros programming language specification (draft) and IDE*,
Soros interpreter in Python: pythonpath/Soros.py,
Soros interpreter in JavaScript: Soros.js*
Soros interpreter in Java: see NUMBERTEXT.org

* Not in LibreOffice Numbertext extension, see http://NUMBERTEXT.org.

Copyright: 2009-2020 (C) László Németh (nemeth at numbertext dot org)
License: LGPL-3+/Modified BSD dual-license

Numbertext language data (Soros programs):
License: LGPL-3+/Modified BSD dual-license (except Serbian)
*/
/* eslint-disable */
let template =
  '^0$ không' +
  '\n' +
  '1 một' +
  '\n' +
  '2 hai' +
  '\n' +
  '3 ba' +
  '\n' +
  '4 bốn' +
  '\n' +
  '^5$ năm' +
  '\n' +
  '5$ lăm' +
  '\n' +
  '5 năm' +
  '\n' +
  '6 sáu' +
  '\n' +
  '7 bảy' +
  '\n' +
  '8 tám' +
  '\n' +
  '9 chín' +
  '\n' +
  '1(\\d) mười[ $1]' +
  '\n' +
  '(\\d)0 $1 mươi' +
  '\n' +
  '(\\d)1 $1 mươi mốt' +
  '\n' +
  '(\\d)(\\d) $1 mươi[ $2]' +
  '\n' +
  '(\\d)01 $1 trăm linh một' +
  '\n' +
  '(\\d)(\\d\\d) $1 trăm[ $2]' +
  '\n' +
  '(\\d{1,3})000 $1 ngàn' +
  '\n' +
  '(\\d{1,3})001 $1 ngàn không trăm linh một' +
  '\n' +
  '(\\d{1,3})0(\\d\\d) $1 ngàn không trăm[ $2]' +
  '\n' +
  '(\\d{1,3})(\\d\\d\\d) $1 ngàn[ $2]' +
  '\n' +
  '(\\d{1,3})0{6} $1 triệu' +
  '\n' +
  '(\\d{1,3})0{5}1 $1 triệu không trăm linh một' +
  '\n' +
  '(\\d{1,3})0{4}(\\d\\d) $1 triệu không trăm[ $2]' +
  '\n' +
  '(\\d{1,3})(\\d{6}) $1 triệu[ $2]' +
  '\n' +
  '(\\d{1,10})0{9} $1 tỷ' +
  '\n' +
  '(\\d{1,10})0{8}1 $1 tỷ không trăm linh một' +
  '\n' +
  '(\\d{1,10})0{7}(\\d\\d) $1 tỷ không trăm[ $2]' +
  '\n' +
  '(\\d{1,10})(\\d{9}) $1 tỷ[ $2]' +
  '\n' +
  '(\\d+)	\\1.' +
  '\n' +
  '"" |$(1)|, |$(2)|, |$(3)|\\n$(help ordinal-number)' +
  '\n' +
  '(.*) \\1: |$(\\1 1)|, |$(\\1 2)|, |$(\\1 3)|\\n';

export function Soros(lang) {
  this.funcpat = /(\|?(\uE008\()+)?(\|?\uE008\(([^\(\)]*)\)\|?)(\)+\|?)?/;
  this.meta = '\\"$()|#;[]';
  this.enc = '\uE000\uE001\uE002\uE003\uE004\uE005\uE006\uE007\uE008\uE009';
  this.lines = [];
  if (!/__numbertext__/.test(template)) template = '__numbertext__;' + template;

  template = template.replace(
    '__numbertext__',
    // default left zero deletion
    '"([a-z][-a-z]* )?0+(0|[1-9]\\d*)" $(\\1\\2);' +
      // separator function
      '"\uE00A(.*)\uE00A(.+)\uE00A(.*)" \\1\\2\\3;' +
      // no separation, if subcall returns with empty string
      '"\uE00A.*\uE00A\uE00A.*"',
  );

  // subclass for line data
  this.linetype = function(regex, repl, begin, end) {
    this.pat = regex;
    this.repl = repl;
    this.begin = begin;
    this.end = end;
  };

  // strip function
  this.strip = function(st, ch) {
    if (st == undefined) return '';
    return st
      .replace(new RegExp('^' + ch + '+'), '')
      .replace(new RegExp(ch + '+$'), '');
  };

  // character translation function
  this.tr = function(text, chars, chars2, delim) {
    for (var i = 0; i < chars.length; i++) {
      var s = delim + chars[i];
      while (text.indexOf(s) >= 0) {
        text = text.replace(s, chars2[i]);
      }
    }
    return text;
  };

  // private run function
  this._run = function(data, begin, end) {
    for (var i in this.lines) {
      var l = this.lines[i];
      if (!((!begin && l.begin) || (!end && l.end))) {
        var m = l.pat.exec(data);
        if (m != null) {
          var s = data.replace(l.pat, l.repl);
          var n = this.funcpat.exec(s);
          while (n != null) {
            var b = false;
            var e = false;
            if (n[3][0] == '|' || n[0][0] == '|') {
              b = true;
            } else if (n.index == 0) {
              b = begin;
            }
            if (n[3][n[0].length - 1] == '|' || n[3][n[0].length - 1] == '|') {
              e = true;
            } else if (n.index + n[0].length == s.length) {
              e = end;
            }
            s =
              s.substring(0, n.index + (n[1] == undefined ? 0 : n[1].length)) +
              this._run(n[4], b, e) +
              s.substring(
                n.index + (n[1] == undefined ? 0 : n[1].length) + n[3].length,
              );
            n = this.funcpat.exec(s);
          }
          return s;
        }
      }
    }
    return '';
  };

  // run with the string input parameter
  this.run = function(data) {
    data = this._run(this.tr(data, this.meta, this.enc, ''), true, true);
    return this.tr(data, this.enc, this.meta, '');
  };

  // constructor
  //    template = template.replace(/\\\\/g, "\uE000")
  //    template = template.replace(/\\[(]/g, "\uE003")
  //    template = template.replace(/\\[)]/g, "\uE004")
  //    template = template.replace(/\\[|]/g, "\uE005")
  template = this.tr(template, this.meta, this.enc, '\\');
  // switch off all country-dependent lines, and switch on the requested ones
  template = template
    .replace(/(^|[\n;])([^\n;#]*#[^\n]*[[]:[^\n:\]]*:][^\n]*)/g, '$1#$2')
    .replace(
      new RegExp(
        '(^|[\n;])#([^\n;#]*#[^\n]*[[]:' + lang.replace('_', '-') + ':][^\n]*)',
        'g',
      ),
      '$1$2',
    );
  var l = template.replace(/(#[^\n]*)?(\n|$)/g, ';').split(';');
  var matchline = new RegExp(/^\s*(\"[^\"]*\"|[^\s]*)\s*(.*[^\s])?\s*$/);
  var prefix = '';
  for (var i in l) {
    var macro = /== *(.*[^ ]?) ==/.exec(l[i]);
    if (macro != null) {
      prefix = macro[1];
      continue;
    }
    var s = matchline.exec(l[i]);
    if (prefix != '' && l[i] != '' && s != null) {
      s1 = this.strip(s[1], '"');
      var empty = s1 == '';
      var start = !empty && s1[0] == '^';
      if (s[2] == undefined) s[2] = '';
      l2 =
        '"' +
        (start ? '^' : '') +
        prefix +
        (empty ? '' : ' ') +
        s1.replace('^^', '') +
        '" ' +
        s[2];
      s = matchline.exec(l2);
    }
    if (s != null) {
      s[1] = this.strip(s[1], '"');
      if (s[2] == undefined) s[2] = '';
      else s[2] = this.strip(s[2], '"');
      var line = new this.linetype(
        new RegExp('^' + s[1].replace('^^', '').replace('$$', '') + '$'),
        s[2]
          .replace(/\\n/g, '\n')
          // call inner separator: [ ... $1 ... ] -> $(\uE00A ... \uE00A$1\uE00A ... )
          .replace(/^[[]\$(\d\d?|\([^\)]+\))/g, '$(\uE00A\uE00A|$$$1\uE00A')
          .replace(
            /[[]([^\$[\\]*)\$(\d\d?|\([^\)]+\))/g,
            '$(\uE00A$1\uE00A$$$2\uE00A',
          )
          .replace(/\uE00A]$/, '|\uE00A)') // add "|" in terminating position
          .replace(/]/g, ')')
          .replace(/(\$\d|\))\|\$/g, '$1||$$') // $(..)|$(..) -> $(..)||$(..)
          .replace(/\$/g, '\uE008')
          .replace(/\\0/g, '$$&')
          .replace(/\\(\d)/g, '$$$1')
          .replace(/\uE008(\d)/g, '\uE008($$$1)'),
        /^\^/.test(s[1]),
        /\$$/.test(s[1]),
      );
      this.lines = this.lines.concat(line);
    }
  }
}

// const a = new Soros(template, 'vi');
// console.log(a.run("0374638"))
