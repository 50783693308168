<template>
  <default-layout>
    <div class="borrower-loan mt-5 px-7 text-center">
      <div class="text-left">
        <label class="font-bold">Số tiền bạn muốn vay </label>
        <template v-if="type === 'personalLoan'">
          <base-input
            v-model:modelValue="amount"
            typeInput="text"
            suffix="VNĐ"
            placeholder="Nhập số tiền"
            mode="numeric"
            :bold="!!amount"
          />
          <base-text-error :errorMessages="errorAmount" />
        </template>
        <template v-else>
          <base-select
            :data="dataAmount"
            v-model:modelValue="amount"
            :bold="!!amount"
            @change="handleChangeAmountInput"
          />
          <span class="text-primary">{{ letterAmount }}</span>
          <base-text-error
            :errorMessages="errorAmount"
            style="color: black; font-style: italic"
          />
        </template>
      </div>
      <div class="text-left">
        <label class="font-bold">Kì hạn</label>
        <base-select :data="dataMonthLoan" v-model:modelValue="term" />
        <base-text-error :errorMessages="errorTerm" />
      </div>
      <div v-if="fillProvince" class="text-left relative">
        <label class="font-bold">Địa chỉ thường trú (thành phố)</label>
        <base-auto-complete
          placeholder="Thành phố"
          name="province"
          v-model:modelValue="province"
          v-model:modelId="provinceId"
          :data="arrProvince"
        />
        <base-text-error :errorMessages="errorProvince" />
      </div>
      <div class="bg-primary rounded-xl text-white mt-5 p-3">
        <div class="flex justify-between items-start mt-1">
          <p>Số tiền thực nhận:</p>
          <p class="font-bold">{{ amount }} VNĐ</p>
        </div>
        <div class="flex justify-between items-start mt-1">
          <p class="text-left">
            Tiền trả hàng tháng: <br />
            <i>(dự kiến)</i>
          </p>
          <p class="font-bold">{{ monthlyPayment }} VNĐ</p>
        </div>
      </div>
      <div class="flex items-baseline text-sm text-left mt-2">
        <input
          id="term"
          type="checkbox"
          class="mr-2"
          v-model="check"
          :checked="check"
        />
        <label for="term" class="select-none cursor-pointer">
          Tôi đồng ý cho CTCP Interloan cung cấp các thông tin đăng ký này cũng
          như thông tin cá nhân cho Công ty Tài chính Shinhan Việt Nam để phục
          vụ việc xét duyệt khoản vay
        </label>
      </div>
      <base-button
        class="w-full border-16 text-white mt-5 mb-10 py-6"
        @click="handleSubmit"
        :disabled="!check"
        :class="{
          'bg-secondary': !check,
          'bg-tertiary': check,
        }"
      >
        Tiếp tục
      </base-button>
    </div>
  </default-layout>
</template>
<script>
import { Soros } from '@/helpers/numberToWords';
import DefaultLayout from '@/layouts/DefaultLayout.vue';
import BaseInput from '@/components/Base/BaseInput.vue';
import BaseButton from '@/components/Base/BaseButton.vue';
import BaseSelect from '@/components/Base/baseSelect.vue';
import BaseTextError from '@/components/Base/BaseTextError.vue';
import constRouter from '@/constants/constRouter';
import { required } from '@/helpers/validate';
import { numberToMoney, isNumeric, moneyToNumber } from '../../utils/index';
import { updateCustomer } from '@/apis/ApiOnboarding';
import { Lead } from '@/apis/ApiLeadGen';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { INTEREST_SHINHAN } from '@/constants';
import { getContractId } from '../../helpers/localStorage';
import { computeMonthlyPayment } from '@/utils/computeFee';
import { calMonthlyPayment } from '@/helpers/currency';
import BaseAutoComplete from '@/components/Base/BaseAutoComplete.vue';
import { getProvinces } from '@/apis/ApiGeneral';

const format = new Soros('vi');
export default {
  components: {
    BaseAutoComplete,
    DefaultLayout,
    BaseInput,
    BaseButton,
    BaseSelect,
    BaseTextError,
  },
  name: 'BorrowerLoan',
  data() {
    return {
      dataMonthLoan: [],
      dataAmount: [
        {
          name: '15.000.000',
          val: '15.000.000',
        },
        {
          name: '20.000.000',
          val: '20.000.000',
        },
      ],
      amount: '',
      errorAmount: '',
      letterAmount: '',
      term: '12',
      errorTerm: '',
      monthlyPayment: '',
      type: '',
      check: false,
      province: '',
      provinceId: undefined,
      errorProvince: '',
      arrProvince: [],
      fillProvince: false,
    };
  },
  computed: {
    ...mapGetters({
      getExigency: 'getExigency',
      getUser: 'getUser',
      campaign: 'getCampaign',
    }),
    calculateMonthlyPayment() {
      const { amount, term } = this;
      return {
        amount,
        term,
      };
    },
  },
  created() {
    this.type = this.$route.params.type;
    if (this.type === 'personalLoan') {
      this.amount = numberToMoney(this.getExigency.amount);
      this.dataMonthLoan = [
        {
          name: '12 Tháng',
          val: 12,
        },
        {
          name: '24 Tháng',
          val: 24,
        },
        {
          name: '36 Tháng',
          val: 36,
        },
        {
          name: '48 Tháng',
          val: 48,
        },
      ];
    } else {
      if (this.getExigency.amount < 16000000) {
        this.amount = '15.000.000';
      } else {
        this.amount = '20.000.000';
      }
      this.errorAmount =
        '"Chương trình chỉ áp dụng 2 hạn mức 15 triệu và 20 triệu"';
      this.dataMonthLoan = [
        {
          name: '12 Tháng',
          val: 12,
        },
        {
          name: '18 Tháng',
          val: 18,
        },
        {
          name: '24 Tháng',
          val: 24,
        },
        {
          name: '30 Tháng',
          val: 30,
        },
        {
          name: '36 Tháng',
          val: 36,
        },
        {
          name: '48 Tháng',
          val: 48,
        },
      ];
    }
    const money = moneyToNumber(this.amount);
    this.monthlyPayment = numberToMoney(
      computeMonthlyPayment(parseInt(this.term), money, INTEREST_SHINHAN, true),
    );

    // check has province id
    const provinceId = this.getUser.address?.province.id;
    if (this.type === 'personalLoan' && !provinceId) {
      this.getProvice();
      this.fillProvince = true;
    } else {
      this.fillProvince = false;
    }
  },
  watch: {
    amount(value) {
      if (value) {
        value = value.replace(/\./g, '');
        if (!isNumeric(value)) {
          return;
        }
        let mes = format.run(value);
        mes = mes.charAt(0).toUpperCase() + mes.slice(1);
        this.letterAmount = mes.replace('0.', '') + ' đồng';
        this.amount = numberToMoney(parseInt(value));
      }
    },
    // term(value) {
    //   const money = moneyToNumber(this.amount);
    //   if (isNumeric(value)) {
    //     this.monthlyPayment = numberToMoney(
    //       calMonthlyPayment(money, INTEREST_SHINHAN, parseInt(value)),
    //     );
    //   }
    // },
    calculateMonthlyPayment(value) {
      if (isNumeric(moneyToNumber(value.amount)) && isNumeric(value.term)) {
        const money = moneyToNumber(value.amount);
        this.monthlyPayment = numberToMoney(
          calMonthlyPayment(money, INTEREST_SHINHAN, parseInt(value.term)),
        );
      }
    },
  },
  methods: {
    ...mapActions({
      fetchUserInfo: 'fetchUserInfo',
    }),
    ...mapMutations({
      setExigency: 'setExigency',
    }),
    handleChangeAmountInput() {
      this.errorAmount = '';
    },
    getProvice() {
      getProvinces()
        .then(res => {
          this.arrProvince = res.data.data;
        })
        .catch(err => {
          console.log(err);
        });
    },
    validation() {
      this.errorAmount = required('Amount', this.amount);
      this.errorTerm = required('Term', this.term);
      let result = true;

      let number = moneyToNumber(this.amount);
      if (this.amount && isNumeric(number)) {
        number = +number;
        if (number < 3000000) {
          this.errorAmount = 'Số tiền tối thiểu là 3 triệu';
          result = false;
        }
        if (number > 300000000) {
          this.errorAmount = 'Số tiền tối đa là 300 triệu';
          result = false;
        }
      }
      if (!this.amount || !this.term) {
        result = false;
      }

      return result;
    },
    validateAddressCity() {
      // only valid if required fillProvince
      if (this.fillProvince) {
        this.errorProvince = required('CityAddress', this.provinceId);
        return this.provinceId;
      }
      return true;
    },
    async handleSubmit() {
      if (this.validation() && this.validateAddressCity()) {
        this.setExigency({
          amount: moneyToNumber(this.amount),
          paidMoney: 0,
          realMoney: moneyToNumber(this.amount),
          monthlyPayment: moneyToNumber(this.monthlyPayment),
          term: this.term,
        });

        const contractId = this.getExigency.contractId;
        await updateCustomer(contractId, {
          provinceId: this.provinceId,
          amount: moneyToNumber(this.amount),
          term: this.term,
        }).catch(err => console.log(err));
        this.fetchUserInfo();
        if (this.type === 'easyLoan') {
          this.$router.push({ name: constRouter.BORROWER_ADDRESS.name });
        } else {
          const { utmSource, utmCampaign, utmContent, clickId } = this.campaign;
          Lead({
            productName: 'personalLoan',
            contractId: this.getExigency.contractId || getContractId(),
            utmSource,
            utmCampaign,
            utmContent,
            clickId,
          });
          this.$router.push({ name: constRouter.REGISTER_SUCCESS.name });
        }
      }
    },
  },
};
</script>
