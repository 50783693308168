<template>
  <div class="base-select border-b border-0">
    <select
      class="focus:outline-none w-full py-1 bg-transparent"
      :value="modelValue"
      @change="handleChoose"
      :class="{
        'font-bold': bold,
      }"
    >
      <option v-for="item in data" :key="item.index" :value="item.val">
        {{ item.name }}
      </option>
    </select>
  </div>
</template>
<script>
export default {
  name: ' BaseSelect',
  props: {
    modelValue: {
      type: String,
    },
    data: {
      type: Array,
      defalut: [],
    },
    bold: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleChoose(event) {
      this.$emit('update:modelValue', event.target.value);
    },
  },
};
</script>
<style lang="scss" scoped>
.base-select {
  select {
    height: 32px;
  }
}
</style>
